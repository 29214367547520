import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { CONSTANTS } from "consts";
import NavUI from "./Sidebar.nav.ui";
import "./sidebar.scss";

const Sidebar: React.FunctionComponent<
  RouteComponentProps<any> & {
    classes?: any;
  }
> = (props) => {
  const { classes } = props;
  const {
    globalDispatch,
    global: { current_page: currentPage },
  } = React.useContext(AdminAppContext);
  const clickMenu: any = (pageId: number, event: any) => {
    const { history } = props;
    // if(currentPage===pageId) {
    //     return;
    // }
    switch (pageId) {
      case CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.SUBSCRIPTION,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.MACHINE:
        history.push({ pathname: CONSTANTS.NAVIGATION_URL.MACHINE });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.USER:
        history.push({ pathname: CONSTANTS.NAVIGATION_URL.USER });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.ADMIN:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.ADMIN_MANAGEMENT,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.SUBSCRIPTION_CANCEL,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.MONTHLY_CHALLENGE:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.MONTHLY_CHALLENGE,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.DEALER_LOCATION:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.DEALER_LOCATION,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_LEVELS:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_LEVELS,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.SUPPORT:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.SUPPORT,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.SUPPORT_POST:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.SUPPORT_POST,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.PRESS:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.PRESS,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIP:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.MEMBERSHIP,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.PLAN:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.PLAN,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.ACHIEVEMENT,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_SETTING:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_SETTING,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_BETA_USER,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_BETA_REQUEST,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIP:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.MEMBERSHIP,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.PLAN:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.PLAN,
        });
        break;
        case CONSTANTS.SIDEBAR_PAGEID.CONTACT_MESSAGE:
          history.push({
            pathname: CONSTANTS.NAVIGATION_URL.CONTACT_MESSAGE,   
          })
          break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_TYPE:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_TYPE,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_OPTION:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_OPTION,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_CATEGORIES:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_CATEGORIES,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_INFO:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_INFO,
        });
        break;
      case CONSTANTS.SIDEBAR_PAGEID.CONTACT_MESSAGE:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.CONTACT_MESSAGE,   
        })
        break;
      case CONSTANTS.SIDEBAR_PAGEID.GENERAL:
        history.push({
          pathname: CONSTANTS.NAVIGATION_URL.GENERAL,
        });
        break;
      default:
        history.push({ pathname: CONSTANTS.NAVIGATION_URL.NOT_FOUND });
        break;
    }
    globalDispatch(
      Action.global.setState({
        current_page: pageId,
      })
    );
  };
  return (
    <div id="sidebar" className={classes.drawerPaper}>
      <div className="sidebar">
        <List>
          <NavUI clickMenu={clickMenu} />
        </List>
      </div>
    </div>
  );
};
Sidebar.defaultProps = {
  classes: {},
};
export default withRouter(Sidebar);
