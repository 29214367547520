export const ENV: any = {
    SERVER_DOMAIN: process.env.REACT_APP_SERVER_DOMAIN,
    GAME_SERVER_HTTP_KEY: process.env.REACT_APP_GAME_SERVER_HTTP_KEY,
    CRM_SERVER_DOMAIN: process.env.REACT_APP_CRM_SERVER_DOMAIN,
    CRM_SERVER_CONTEXT: process.env.REACT_APP_CRM_SERVER_CONTEXT,
    GAME_SERVER_DOMAIN: "https://" + process.env.REACT_APP_GAME_SV_URL,
    PAYMENT: {
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
        STRIPE_CA_PUBLIC_KEY: process.env.REACT_APP_STRIPE_CA_PUBLIC_KEY
    },
    UPLOAD_SERVICE_URL: process.env.REACT_APP_UPLOAD_SERVICE_URL,
    CLIENT_ID_UPLOAD: process.env.REACT_APP_CLIENT_ID_UPLOAD,
    SECRET_KEY_UPLOAD: process.env.REACT_APP_SECRET_KEY_UPLOAD,
}   
