import React from "react";
import style from "./ListTable.module.scss";
import Tabs from "Components/base/Tabs";
import classNames from "classnames";

interface ListTable {
  content: any;
  header?: {
    title?: any;
    statusTabs?: any;
    filter?: any;
    inputSearch?: any;
    statusTab?: {
      options?: any;
      handleChange?: any;
      itemActive?: any;
    };
  };
  className?: string;
  customWidthInputSearch?: boolean;
}
const ListTable = (props: any) => {
  const { content, header, customWidthInputSearch } = props;
  const {
    title,
    filter,
    inputSearch,
    statusTab,
    selectFilter,
    button,
    selectFilterMulti,
  } = header;
  const handleChangeStatus = (activeIndex: any) => {
    const onChangeFilter = statusTab?.handleChange;
    onChangeFilter && onChangeFilter("state", activeIndex);
  };
  return (
    <div className={style["ListTable"] + " listTable"}>
      <div className={style["header"] + " ListTable_header"}>
        {title && !statusTab && (
          <div className={style["title"]}>
            <h2>{title}</h2>
          </div>
        )}
        {statusTab && (
          <div className={style["statusTabs"]}>
            <Tabs
              options={statusTab?.options}
              handleChangeTab={handleChangeStatus}
              defaultActive={statusTab.itemActive}
            />
          </div>
        )}
        <div className={style["filterGroup"]}>
          {selectFilter && (
            <div className={style["selectFilter"]}>{selectFilter}</div>
          )}
          {selectFilterMulti && (
            <div className={style["selectFilterMulti"]}>
              {selectFilterMulti}
            </div>
          )}
          {inputSearch && (
            <div
              className={classNames(style["inputSeach"] + " inputSeach", {
                [style["customWidthInputSearch"]]: customWidthInputSearch,
              })}
            >
              {inputSearch}
            </div>
          )}
          {button && <div className={style["button"]}>{button}</div>}
          {filter && <div className={style["filter"]}>{filter}</div>}
        </div>
      </div>
      <div className={style["content"]}>{content}</div>
    </div>
  );
};

export default ListTable;
