import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import Dropzone from "react-dropzone";
import "./ImageUpload.scss";

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 3,
    border: "1px solid #a8dbe0",
    marginBottom: 8,
    marginRight: 8,
    width: 120,
    height: 120,
    padding: 4,
    boxSizing: "border-box",
    position: "relative",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
};
export const ImageUpload = (props) => {
    const [state, setState] = React.useState({
        uploadFiles: [],
        thumbs: [],
        defaultValue: props.defaultValue,
    });
 
    React.useMemo(() => {
        if (props.defaultValue && typeof props.defaultValue === "string") {
            state.thumbs = [
                <div style={thumb} key={props.defaultValue}>
                    <div style={thumbInner}>
                        <img src={props.defaultValue} style={img} alt="upload"/>
                    </div>
                    <div
                        onClick={() => {
                            removeFile(props.defaultValue);
                        }}
                        className="pointer btn-delete-image-preview"
                    >
                        <CancelIcon color="white" />
                    </div>
                </div>,
            ];
            setState({
                thumbs: state.thumbs,
                defaultValue: props.defaultValue,
            });
        }
    }, [props.defaultValue]);
    const onDrop = (acceptedFiles) => {
        if (props.multi) {
            state.thumbs = state.thumbs.concat(
                acceptedFiles.map((file) => {
                    const url = URL.createObjectURL(file);
                    return (
                        <div style={thumb} key={file.name}>
                            <div style={thumbInner}>
                                <img src={url} style={img} alt="upload"/>
                            </div>
                            <div
                                onClick={() => {
                                    removeFile(file.name);
                                }}
                                className="pointer btn-delete-image-preview"
                            >
                                <CancelIcon color="white" />
                            </div>
                        </div>
                    );
                })
            );

            setState({
                uploadFiles: state.uploadFiles.concat(acceptedFiles),
                thumbs: state.thumbs,
            });
            props.changeImage(
                state.uploadFiles,
                state.thumbs.map((thumb) => {
                    return thumb.key;
                })
            );
        } else {
            let fileUrl = URL.createObjectURL(acceptedFiles[0]);
            const thumbs = acceptedFiles.map((file) => {
                const url = URL.createObjectURL(file);
                return (
                    <div style={thumb} key={file.name}>
                        <div style={thumbInner}>
                            <img src={url} style={img} alt="upload"/>
                        </div>
                        <div
                            onClick={() => {
                                removeFile(file.name);
                            }}
                            className="pointer btn-delete-image-preview"
                        >
                            <CancelIcon color="white" />
                        </div>
                    </div>
                );
            });

            setState({ uploadFiles: acceptedFiles, thumbs: thumbs });
            props.changeImage(acceptedFiles[0], fileUrl);
        }
    };

    const removeFile = (name) => {
        const thumbIndex = state.thumbs.findIndex((thumb) => {
            return thumb.key === name;
        });
        if (thumbIndex !== -1) {
            state.thumbs.splice(thumbIndex, 1);
        }
        const uploadedIndex = state.uploadFiles.findIndex((uploadFile) => {
            return uploadFile.name === name;
        });
        if (uploadedIndex !== -1) {
            state.uploadFiles.splice(uploadedIndex, 1);
        }
        props.changeImage(
            state.uploadFiles,
            state.thumbs.map((thumb) => {
                return thumb.key;
            })
        );
        setState({ uploadFiles: state.uploadFiles, thumbs: state.thumbs });
    };
    return (
        <div>
            <div
                className={`drop-zone-wrap ${
                    props.error ? "drop-zone-wrap--error" : ""
                }`}
            >
                <Dropzone
                    onDrop={onDrop}
                    accept="image/png, image/jpg, image/jpeg"
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            className="drop-zone-wrap__instruction"
                        >
                            <input {...getInputProps()} />
                            <div>
                                {props.title || "Click here to upload image"}
                            </div>
                            <div></div>
                        </div>
                    )}
                </Dropzone>
            </div>
            <aside style={thumbsContainer}>{state.thumbs}</aside>
        </div>
    );
};
