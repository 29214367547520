import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import * as React from "react";

const EnhancedTableHead: React.FunctionComponent<{
    columns: any;
    onRequestSort?: (event: Event, property: any) => void;
    order: string;
    orderBy: string;
    action: boolean;
    isHideNumberCol?: boolean
}> = (props) => {
    const { columns, onRequestSort, order, orderBy, action, isHideNumberCol } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort && onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {!isHideNumberCol && (
            <TableCell>
              <span className="column-no">No#</span>
            </TableCell>
          )}
          {columns.map((column: any) => (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? "none" : "normal"}
              // sortDirection={
              //     orderBy === column.id ? order : (false as any)
              // }
            >
              {/* <Tooltip
                            title="Sort"
                            placement={
                                column.numeric ? "bottom-end" : "bottom-start"
                            }
                            enterDelay={300}
                        >
                            <TableSortLabel
                                active={orderBy === column.id}
                                direction={order as any}
                                onClick={createSortHandler(column.id)}
                            > */}
              {column.label}
              {/* </TableSortLabel>
                        </Tooltip> */}
            </TableCell>
          ))}
          {action ? (
            <TableCell key="action">
              <span className="column-no">Actions</span>
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
};

export default EnhancedTableHead;
