import { BaseAPI } from "../base";
import { ENV } from "scripts/apps/environment";

export interface GetChallengesFilter {
    upToYear: number,
    upToMonth: number,
    numMonths: number
}

export interface MonthlyChallengeCreatePayload {
    Id: string,
    Title: string,
    Description: string,
    sortOrder: "desc" | "asc",
    Operator: "best" | "set" | "incr" | "decr",
    Category: number,
    Metadata: MonthlyChallengeCreateMetaData
}

export interface MonthlyChallengeCreateMetaData {
    Type: string,
    Year: number,
    Month: number,
    Target: number,
    AvatarUrl: string,
    TargetType: "Streak" | "Meters" | "Outputs" | "Calories" | "Strokes" | "Hours" | "Workouts",
    SubTitle: string
}

export interface MonthlyChallengeMetaData {
    type: string,
    year: number,
    month: number,
    target: number,
    avatarUrl: string,
    targetType: "Streak" | "Meters" | "Outputs" | "Calories" | "Strokes" | "Hours" | "Workouts",
    subTitle: string
}

export interface MonthlyChallenge {
    id: string,
    title: string,
    description: string,
    category: number,
    sortOrder: "desc" | "asc",
    operator: "best" | "set" | "incr" | "decr",
    metaData: MonthlyChallengeMetaData
}

export type GameEnviroment = "dev" | "alpha" | "beta" | "prod"
export class MonthlyChallengeAPI extends BaseAPI {
    httpKey?: string
    constructor(environment?: GameEnviroment) {
        switch (environment) {
            case "dev":
                super("https://social-apk.dev.avironactive.net" + "/v2/rpc");
                this.httpKey = "defaulthttpkey";
                break;
            case "alpha":
                super("https://social-apk.alpha.avironactive.net" + "/v2/rpc");
                this.httpKey = "sMHs5Funf2rJbzHp";
                break;
            // case "beta":
            //     super("" + "/v2/rpc");
            //     this.httpKey = "";
            //     break;
            // case "prod":
            //     super("" + "/v2/rpc");
            //     this.httpKey = "";
            //     break;
            default:
                console.log("default env")
                super(ENV.GAME_SERVER_DOMAIN + "/v2/rpc");
                this.httpKey = ENV.GAME_SERVER_HTTP_KEY;
                break;
        }

    }

    async getAllChallenges(environment?: GameEnviroment) {
        let url: string = this._parseUrl("get_challenges")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: {}
        }
        const res: any = await this._exec(url, options)
        return res
    }
    async getChallenges(option: GetChallengesFilter | any = {}, environment?: GameEnviroment) {
        let url: string = this._parseUrl("get_challenges")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(option)
        }
        const res: any = await this._exec(url, options)
        return res
    }
    async getChallenge(id: string, environment?: GameEnviroment) {
        let url: string = this._parseUrl("get_challenge")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({ id })
        }
        const res: any = await this._exec(url, options)
        return res
    }
    async createChallenge(data: MonthlyChallengeCreatePayload, environment?: GameEnviroment) {
        let url: string = this._parseUrl("create_challenge")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        }
        const res: any = await fetch(url, options);
        return res
    }
    async updateChallenge(id: string, data: any, environment?: GameEnviroment) {
        let url: string = this._parseUrl("update_challenge")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(Object.assign({ id }, data))
        }
        const res: any = await fetch(url, options);
        return res
    }
    async getChallengeRecords(id: string, environment?: GameEnviroment) {
        let url: string = this._parseUrl("get_new_challenge_records")
        const query = this._serialize({
            http_key: this.httpKey,
            unwrap: true
        });
        url += `${query}`;
        const options: any = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({ id })
        }
        const res: any = await this._exec(url, options)
        return res
    }
}