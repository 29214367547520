const API_DOMAIN = "http://localhost:4040/";
const ROOT_FILE = "";
const CONSTANTS = {
  NOTIFY_TYPES: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning",
  },
  LOGIN_METHOD: {
    LOCAL: "local",
    GOOGLE: "google-token",
    FACEBOOK: "facebook-token",
    OTP: "otp",
  },
  USER: {
    ROLE: {
      AUTH_USER: "authenticated",
      ADMIN: "administrator",
      STAFF: "staff",
    },
  },
  PASSWORD_API_ACTION: {
    RESET_PASSWORD: "resetPwdLong",
    SEND_RESET_PASSWORD: "sendResetPwd",
    CHANGE_PASSWORD: "passwordChange",
  },
  EMAIL_API: {
    VERIFY_SIGNUP_LONG: "verifySignupLong",
    RESEND_VERIFY_SIGNUP: "resendVerifySignup",
    CHANGE_EMAIL: "identityChange",
    CHECK_UNIQUE: "checkUnique",
  },
  STORAGE_NAME: {
    ACCESS_TOKEN_KEY_NAME: "ACCESS_TOKEN",
    USER_INFO: "USER_INFO",
  },
  INPUT_LENGTH: {
    PASSWORD_MIN: 6,
  },
  NOTIFY_USER: {
    PASSWORD_LENGTH: "Password must be at least {0} characters",
    CONFIRM_PASSWORD_ERROR: "Those passwords didn't match. Try again.",
    EMAIL: {
      VERIFY_FAIL: "Verify fail!",
      VERIFY_SUCCESS: "Verify Successfully!",
      YOUR_EMAIL_VERIFIED: "Your email verified!",
      YOUR_EMAIL_VERIFIED_NOTE: "Your email is only verified once",
      SEND_SUCCESS: "Send Successfully!",
      SEND_SUCCESS_NOTE: "Please check your email to verify",
      SEND_FAIL: "Send mail not success!",
      SEND_FAIL_NOTE: "Try again!",
      EMAIL_NOT_VERIFY: "Your email not verify!",
      EMAIL_NOT_VERIFY_NOTE: "Please check your email to verify",
      CHECK_EMAIL_TO_RESET_PASSWORD:
        "Please check your email to reset password",
    },
  },
  SERVER_ERROR: {
    GENERAL: {
      BAD_REQUEST: "bad-request",
    },
    EMAIL: {
      IS_NOT_VERIFIED: "isNotVerified",
    },
  },
  PAYMENT: {
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  },
  NAVIGATION_URL: {
    ROOT_FILE: "",
    HOME: `/`,
    DASHBOARD: "/dashboard",
    NOT_FOUND: `/not-found`,
    SUBSCRIPTION: `/subscription`,
    SUBSCRIPTION_DETAIL: `/subscription/:id`,
    PLAN: `/plan`,
    PLAN_ADD: `/plan/add`,
    PLAN_DETAIL: `/plan/detail/:id`,
    MACHINE: `/machine`,
    MACHINE_DETAIL: `/machine/:id`,
    USER: `/user`,
    USER_DETAIL: `/user/:userId`,
    SUBSCRIPTION_CANCEL: `/subscription_cancel`,
    SUBSCRIPTION_CANCEL_DETAIL: `/subscripotion_cancel/:id`,
    MEMBERSHIP: `/membership`,
    MEMBERSHIP_DETAIL: `/membership/:id`,
    BLOG: `/blog`,
    BLOG_DETAIL: `/blog/:id`,
    POST: `/post`,
    POST_ADD: `/post/add`,
    POST_DETAIL: `/post/detail/:id`,
    POST_PREVIEW: `/post/:id/preview`,
    EXPERIENCE_CATEGORY: `/experience-category`,
    EXPERIENCE_CATEGORY_DETAIL: `/experience-category/:id`,
    EXPERIENCE: `/experience`,
    EXPERIENCE_ADD: `/experience/add`,
    EXPERIENCE_DETAIL: `/experience/detail/:id`,
    CONTENT_SUMMARY: `/content-summary`,
    WORKOUT_SUMMARY: `/workout-summary`,
    MONTHLY_CHALLENGE: `/monthly-challenge`,
    MONTHLY_CHALLENGE_ADD: `/monthly-challenge/add`,
    MONTHLY_CHALLENGE_DETAIL: `/monthly-challenge/detail/:id/:appId`,
    DEALER_LOCATION: `/dealer-location`,
    DEALER_LOCATION_ADD: `/dealer-location/add`,
    DEALER_LOCATION_DETAIL: `/dealer-location/detail/:id`,
    GAME_LEVELS: `/game-levels`,
    GAME_LEVELS_ADD: `/game-levels/add`,
    GAME_LEVELS_DETAIL: `/game-levels/detail/:id`,
    GAME_LEVELS_REQUIREMENTS: `/game-levels/requirements`,
    GAME_LEVELS_BUFF: `/game-levels/buff`,
    ADMIN: `/admin`,
    ADMIN_MANAGEMENT: `/admin/management`,
    ADMIN_HISTORY: `/admin/history`,
    ADMIN_DETAIL: `/admin/detail/:adminId`,
    RESET_PASSWORD: `/reset-password`,
    FORGOT_PASSWORD: `/forgot-password`,
    LOGIN: `/login`,
    SUPPORT: `/support`,
    SUPPORT_ADD: `/support/add`,
    SUPPORT_DETAIL: `/support/detail/:id`,
    SUPPORT_POST: `/support-post`,
    SUPPORT_POST_ADD: `/support-post/add`,
    SUPPORT_POST_DETAIL: `/support-post/detail/:id`,
    PRESS: `/press`,
    PRESS_ADD: `/press/add`,
    PRESS_DETAIL: `/press/detail/:id`,
    ACHIEVEMENT: `/achievement`,
    ACHIEVEMENT_ADD: `/achievement/add`,
    ACHIEVEMENT_DETAIL: `/achievement/detail/:id/:appId`,
    GAME_SETTING: `/game-setting`,
    GAME_SHOP_CATEGORY: `/game-shop-category`,
    GAME_SHOP_CATEGORY_ADD: `/game-shop-category/add`,
    GAME_SHOP_CATEGORY_DETAIL: `/game-shop-category/detail/:id`,
    GAME_SHOP_PRODUCT: `/game-shop-product`,
    GAME_SHOP_PRODUCT_ADD: `/game-shop-product/add`,
    GAME_SHOP_PRODUCT_DETAIL: `/game-shop-product/detail/:id`,
    GAME_BETA_USER: `/game-beta-user`,
    GAME_BETA_REQUEST: `/game-beta-request`,
    GAME_INFO: "/game-info",
    GAME_INFO_GAME_TYPE: `/game-info/game-types`,
    GAME_INFO_GAME_OPTION: `/game-info/game-options`,
    GAME_INFO_GAME_CATEGORIES: `/game-info/game-categories`,
    GAME_INFO_GAME_INFO: `/game-info/game-info`,
    GAME_INFO_GAME_INFO_ADD: `/game-info/game-info/add`,
    GAME_INFO_GAME_INFO_DETAIL: `/game-info/game-info/:id/:appId`,
    CONTACT_MESSAGE: `/contact-message`,
    CONTACT_MESSAGE_DETAIL: `/contact-message/detail/:id`,
    GENERAL: `/general`
  },
  SIDEBAR_PAGEID: {
    NONE: -1,
    SUBSCRIPTION: 100,
    SUBSCRIPTION_CANCEL: 101,
    MEMBERSHIP: 102,
    PLAN: 103,
    MACHINE: 200,
    USER: 300,
    CONTENT_SUMMARY: 400,
    WORKOUT_SUMMARY: 500,
    ADMIN: 600,
    POST: 800,
    EXPERIENCE_CATEGORY: 900,
    EXPERIENCE: 1000,
    MONTHLY_CHALLENGE: 1100,
    DEALER_LOCATION: 1200,
    GAME_LEVELS: 1300,
    DASHBOARD: 1400,
    SUPPORT: 1500,
    SUPPORT_POST: 1501,
    PRESS: 1600,
    ACHIEVEMENT: 1700,
    GAME_SETTING: 1800,
    GAME_SHOP_CATEGORY: 1900,
    GAME_SHOP_PRODUCT: 1901,
    GAME_BETA_USER: 1902,
    GAME_BETA_REQUEST: 1903,
    GAME_INFO_GAME_TYPE: 2001,
    GAME_INFO_GAME_OPTION: 2002,
    GAME_INFO_GAME_CATEGORIES: 2003,
    GAME_INFO_GAME_INFO: 2004,
    CONTACT_MESSAGE: 3000,
    GENERAL: 2300,
  },
  ADMIN_PAGE: {
    CURRENT_TAB: {
      NONE: -1,
      MANAGEMENT: 0,
      HISTORY: 1,
    },
    STORAGE_NAME: {
        ACCESS_TOKEN_KEY_NAME: "ACCESS_TOKEN",
        USER_INFO: "USER_INFO",
    },
    INPUT_LENGTH: {
        PASSWORD_MIN: 6,
    },
    NOTIFY_USER: {
        PASSWORD_LENGTH: "Password must be at least {0} characters",
        CONFIRM_PASSWORD_ERROR: "Those passwords didn't match. Try again.",
        EMAIL: {
            VERIFY_FAIL: "Verify fail!",
            VERIFY_SUCCESS: "Verify Successfully!",
            YOUR_EMAIL_VERIFIED: "Your email verified!",
            YOUR_EMAIL_VERIFIED_NOTE: "Your email is only verified once",
            SEND_SUCCESS: "Send Successfully!",
            SEND_SUCCESS_NOTE: "Please check your email to verify",
            SEND_FAIL: "Send mail not success!",
            SEND_FAIL_NOTE: "Try again!",
            EMAIL_NOT_VERIFY: "Your email not verify!",
            EMAIL_NOT_VERIFY_NOTE: "Please check your email to verify",
            CHECK_EMAIL_TO_RESET_PASSWORD:
                "Please check your email to reset password",
        },
    },
    SERVER_ERROR: {
        GENERAL: {
            BAD_REQUEST: "bad-request",
        },
        EMAIL: {
            IS_NOT_VERIFIED: "isNotVerified",
        },
    },
    PAYMENT: {
        STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
    NAVIGATION_URL: {
        ROOT_FILE: "",
        HOME: `/`,
        DASHBOARD: "/dashboard",
        NOT_FOUND: `/not-found`,
        SUBSCRIPTION: `/subscription`,
        SUBSCRIPTION_DETAIL: `/subscription/:id`,
        PLAN: `/plan`,
        PLAN_ADD: `/plan/add`,
        PLAN_DETAIL: `/plan/detail/:id`,
        MACHINE: `/machine`,
        MACHINE_DETAIL: `/machine/:id`,
        USER: `/user`,
        USER_DETAIL: `/user/:userId`,
        SUBSCRIPTION_CANCEL: `/subscription_cancel`,
        SUBSCRIPTION_CANCEL_DETAIL: `/subscripotion_cancel/:id`,
        MEMBERSHIP: `/membership`,
        MEMBERSHIP_DETAIL: `/membership/:id`,
        BLOG: `/blog`,
        BLOG_DETAIL: `/blog/:id`,
        POST: `/post`,
        POST_ADD: `/post/add`,
        POST_DETAIL: `/post/detail/:id`,
        POST_PREVIEW: `/post/:id/preview`,
        EXPERIENCE_CATEGORY: `/experience-category`,
        EXPERIENCE_CATEGORY_DETAIL: `/experience-category/:id`,
        EXPERIENCE: `/experience`,
        EXPERIENCE_ADD: `/experience/add`,
        EXPERIENCE_DETAIL: `/experience/detail/:id`,
        CONTENT_SUMMARY: `/content-summary`,
        WORKOUT_SUMMARY: `/workout-summary`,
        MONTHLY_CHALLENGE: `/monthly-challenge`,
        MONTHLY_CHALLENGE_ADD: `/monthly-challenge/add`,
        MONTHLY_CHALLENGE_DETAIL: `/monthly-challenge/detail/:id/:appId`,
        DEALER_LOCATION: `/dealer-location`,
        DEALER_LOCATION_ADD: `/dealer-location/add`,
        DEALER_LOCATION_DETAIL: `/dealer-location/detail/:id`,
        GAME_LEVELS: `/game-levels`,
        GAME_LEVELS_ADD: `/game-levels/add`,
        GAME_LEVELS_DETAIL: `/game-levels/detail/:id`,
        GAME_LEVELS_REQUIREMENTS: `/game-levels/requirements`,
        GAME_LEVELS_BUFF: `/game-levels/buff`,
        ADMIN: `/admin`,
        ADMIN_MANAGEMENT: `/admin/management`,
        ADMIN_HISTORY: `/admin/history`,
        ADMIN_DETAIL: `/admin/detail/:adminId`,
        RESET_PASSWORD: `/reset-password`,
        FORGOT_PASSWORD: `/forgot-password`,
        LOGIN: `/login`,
        SUPPORT: `/support`,
        SUPPORT_ADD: `/support/add`,
        SUPPORT_DETAIL: `/support/detail/:id`,
        SUPPORT_POST: `/support-post`,
        SUPPORT_POST_ADD: `/support-post/add`,
        SUPPORT_POST_DETAIL: `/support-post/detail/:id`,
        PRESS: `/press`,
        PRESS_ADD: `/press/add`,
        PRESS_DETAIL: `/press/detail/:id`,
        ACHIEVEMENT: `/achievement`,
        ACHIEVEMENT_ADD: `/achievement/add`,
        ACHIEVEMENT_DETAIL: `/achievement/detail/:id/:appId`,
        GAME_SETTING: `/game-setting`,
        GAME_SHOP_CATEGORY: `/game-shop-category`,
        GAME_SHOP_CATEGORY_ADD: `/game-shop-category/add`,
        GAME_SHOP_CATEGORY_DETAIL: `/game-shop-category/detail/:id`,
        GAME_SHOP_PRODUCT: `/game-shop-product`,
        GAME_SHOP_PRODUCT_ADD: `/game-shop-product/add`,
        GAME_SHOP_PRODUCT_DETAIL: `/game-shop-product/detail/:id`,
        GAME_BETA_USER: `/game-beta-user`,
        GAME_BETA_REQUEST: `/game-beta-request`,
        CONTACT_MESSAGE: `/contact-message`,
        CONTACT_MESSAGE_DETAIL: `/contact-message/detail/:id`,
        GAME_INFO: "/game-info",
        GAME_INFO_GAME_TYPE: `/game-info/game-types`,
        GAME_INFO_GAME_OPTION: `/game-info/game-options`,
        GAME_INFO_GAME_CATEGORIES: `/game-info/game-categories`,
        GAME_INFO_GAME_INFO: `/game-info/game-info`,
        GAME_INFO_GAME_INFO_ADD: `/game-info/game-info/add`,
        GAME_INFO_GAME_INFO_DETAIL: `/game-info/game-info/:id/:appId`,
    },
    SIDEBAR_PAGEID: {
        NONE: -1,
        SUBSCRIPTION: 100,
        SUBSCRIPTION_CANCEL: 101,
        MEMBERSHIP: 102,
        PLAN: 103,
        MACHINE: 200,
        USER: 300,
        CONTENT_SUMMARY: 400,
        WORKOUT_SUMMARY: 500,
        ADMIN: 600,
        POST: 800,
        EXPERIENCE_CATEGORY: 900,
        EXPERIENCE: 1000,
        MONTHLY_CHALLENGE: 1100,
        DEALER_LOCATION: 1200,
        GAME_LEVELS: 1300,
        DASHBOARD: 1400,
        SUPPORT: 1500,
        SUPPORT_POST: 1501,
        PRESS: 1600,
        ACHIEVEMENT: 1700,
        GAME_SETTING: 1800,
        GAME_SHOP_CATEGORY: 1900,
        GAME_SHOP_PRODUCT: 1901,
        GAME_BETA_USER: 1902,
        GAME_BETA_REQUEST: 1903,
        GAME_INFO_GAME_TYPE: 2001,
        GAME_INFO_GAME_OPTION: 2002,
        GAME_INFO_GAME_CATEGORIES: 2003,
        GAME_INFO_GAME_INFO: 2004,
        CONTACT_MESSAGE: 3000,
    },
    ADMIN_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            MANAGEMENT: 0,
            HISTORY: 1,
        },
    },
    SUBSCRIPTION_PAGE: {
        CURRENT_TAB: {
            NONE: -1,
            MANAGEMENT: 0,
            MACHINE: 1,
        },
    },
    // ROWS_PER_PAGE: [5, 10, 20],
    ROWS_PER_PAGE: [10],
    GAME_TYPE: {
        kGameTypeWarmUp: 0,
        kGameTypeLightningLap: 1,
        kGameTypeHeadToHead: 2,
        kGameTypeGrandprix: 3,
        kGameTypeZombieLastHope: 4,
        kGameTypeZombieTheHunted: 5,
        kGameTypeZombieApocalypse: 6,
        kGameTypeVirtualWorkout: 7,
        kGameTypeMetricMonitor: 8,
        kGameTypeWorkoutProgram: 9,
        kGameTypeAthleteBattle: 10,
        kGameTypeKraken: 11,
        kGameTypeKrakenRace: 12,
        kGameTypeKrakenUnited: 13,
        kGameTypeVideo: 14,
        kGameTypeBlaster: 15,
        kGameTypeKnockout: 16,
        kGameTypePacer: 17,
        kGameTypeTargetPractice: 18,
        kGameTypePong: 19,
        kGameTypePong2: 20,
        kGameTypeBrickBreaker: 21,
        kGameTypeYoutube: 22,
        kGameTypeBlasterMultiPlayer: 23,
        kGameTypeRacingProgram: 24,
        kGameTypeStunami: 25,
        kGameTypeMonsterShooting: 26,
        kGameTypeSnowShooting: 27,
        kGameTypeCoachedProgram: 28,
        kGameTypeStreaming: 29,
        kGameTypeNum: 30,
    },
    TIME_TYPE: {
        ALL: "all",
        DAY: "day",
        WEEK: "week",
        MONTH: "month",
    },
    METRIC_TYPE: {
        OUTPUTS: "outputs",
        AVG_WATTS: "avgWatts",
        CALORIES: "calories",
        AVG_CALORIES: "avgCalories",
        METERS: "meters",
        AVG_MIN500S: "avgMin500s",
        AVG_SPMS: "avgSpms",
    },
    STATUS_TYPE: {
        ACTIVE: "active",
        INACTIVE: "inactive",
        CANCELLED: "canceled",
        ENDED: "ended",
    },
    WORKOUT_QUERY_RANGE: {
        QUERY_RANGE_ALL: 0,
        QUERY_RANGE_LAST_24H: 1,
        QUERY_RANGE_ALL_LAST_7DAYS: 2,
        QUERY_RANGE_ALL_LAST_30DAYS: 3,
    },
    WORKOUT_PARAMETER: {
        kDIndexKiloJoules: 0,
        kDIndexWatt: 1,
        kDIndexCalories: 2,
        kDIndexCalPerHour: 3,
        kDIndexMeters: 4,
        kDIndexSecPer500: 5,
        kDIndexSPM: 6,
        kDIndexHeartRate: 7,
        kDIndexKiloJoulesPerStroke: 8,
        kDIndexNum: 9,
    },
    WORKOUT_HISTORY_LIMIT: 4,
    HEIGHT_UNITS: {
        IN: 0,
        CM: 1,
        // CM: "cm"
    },
    WEIGHT_UNITS: {
        LBS: 0,
        KGS: 1,
    },
    PERMISSION: {
        staff: "aviron_staff",
        admin: "aviron_admin",
        supperAdmin: "aviron_super_admin",
    },
  },
  // ROWS_PER_PAGE: [5, 10, 20],
  ROWS_PER_PAGE: [10],
  GAME_TYPE: {
    kGameTypeWarmUp: 0,
    kGameTypeLightningLap: 1,
    kGameTypeHeadToHead: 2,
    kGameTypeGrandprix: 3,
    kGameTypeZombieLastHope: 4,
    kGameTypeZombieTheHunted: 5,
    kGameTypeZombieApocalypse: 6,
    kGameTypeVirtualWorkout: 7,
    kGameTypeMetricMonitor: 8,
    kGameTypeWorkoutProgram: 9,
    kGameTypeAthleteBattle: 10,
    kGameTypeKraken: 11,
    kGameTypeKrakenRace: 12,
    kGameTypeKrakenUnited: 13,
    kGameTypeVideo: 14,
    kGameTypeBlaster: 15,
    kGameTypeKnockout: 16,
    kGameTypePacer: 17,
    kGameTypeTargetPractice: 18,
    kGameTypePong: 19,
    kGameTypePong2: 20,
    kGameTypeBrickBreaker: 21,
    kGameTypeYoutube: 22,
    kGameTypeBlasterMultiPlayer: 23,
    kGameTypeRacingProgram: 24,
    kGameTypeStunami: 25,
    kGameTypeMonsterShooting: 26,
    kGameTypeSnowShooting: 27,
    kGameTypeCoachedProgram: 28,
    kGameTypeStreaming: 29,
    kGameTypeNum: 30,
  },
  TIME_TYPE: {
    ALL: "all",
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
  },
  METRIC_TYPE: {
    OUTPUTS: "outputs",
    AVG_WATTS: "avgWatts",
    CALORIES: "calories",
    AVG_CALORIES: "avgCalories",
    METERS: "meters",
    AVG_MIN500S: "avgMin500s",
    AVG_SPMS: "avgSpms",
  },
  STATUS_TYPE: {
    ACTIVE: "active",
    INACTIVE: "inactive",
    CANCELLED: "canceled",
    ENDED: "ended",
  },
  WORKOUT_QUERY_RANGE: {
    QUERY_RANGE_ALL: 0,
    QUERY_RANGE_LAST_24H: 1,
    QUERY_RANGE_ALL_LAST_7DAYS: 2,
    QUERY_RANGE_ALL_LAST_30DAYS: 3,
  },
  WORKOUT_PARAMETER: {
    kDIndexKiloJoules: 0,
    kDIndexWatt: 1,
    kDIndexCalories: 2,
    kDIndexCalPerHour: 3,
    kDIndexMeters: 4,
    kDIndexSecPer500: 5,
    kDIndexSPM: 6,
    kDIndexHeartRate: 7,
    kDIndexKiloJoulesPerStroke: 8,
    kDIndexNum: 9,
  },
  WORKOUT_HISTORY_LIMIT: 4,
  HEIGHT_UNITS: {
    IN: 0,
    CM: 1,
    // CM: "cm"
  },
  WEIGHT_UNITS: {
    LBS: 0,
    KGS: 1,
  },
  PERMISSION: {
    staff: "aviron_staff",
    admin: "aviron_admin",
    supperAdmin: "aviron_super_admin",
  },
    APP: {
        AVIRON_APK:1,
        C2_ROWER: 2,
        C2_SKIERG: 4,
        C2_BIKEERG: 8,
        AVIRON_GO: 16,
        WATER_ROWER: 64,
        MEMBERSHIP_C2: 14,
        MEMBERSHIP_GO: 16,
        AVIRON_BIKE: 128,
        BIKE_GO: 256,
    }
};
export const AVIRON_ROLE = {
  aviron_staff: {
    key: "aviron_staff",
    name: "Staff",
  },
  aviron_admin: {
    key: "aviron_admin",
    name: "Admin",
  },
  aviron_super_admin: {
    key: "aviron_super_admin",
    name: "Super Admin",
  },
};

export { API_DOMAIN, ROOT_FILE, CONSTANTS };
