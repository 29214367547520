import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Select,
    Button,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameInfoHook from "./GameInfo.hook";
import { ReactComponent as EditIcon } from "Components/base/Icons/EditIcon.svg";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import { torem } from "styles/theme/toRem";
import InputDelay from "Components/base/Input/InputSearchDelay";
import PaginationControlled from "Components/PaginationControlled/PaginationControlled";
import style from "./GameInfo.module.scss";

const ROWS_PER_PAGE = 20

const allowUsers = ["Normal", "Demo", "Tester", "Guest"]
const tags = ["strength", "cardiovascular", "interval", "Easy", "Medium", "Hard", ">40mins", "<10mins", "10-19mins", "20-39mins"]

export const GameInfoScr = () => {
    const {
        loading,
        gameInfo,
        columns,
        apps,
        currentApp,
        onChangeApp,
        handleSearch,
        handleChangePage,
        currentPage,
        totalPage,
        filterType,
        gameTypes,
        currentGt,
    } = useGameInfoHook();

    const history = useHistory()

    const onClickAddMore = () => {
        const path = `${CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_INFO_ADD}`;
        history.push({ pathname: path });
    }

    const onClickEdit = (id: any) => {
        const path = `${CONSTANTS.NAVIGATION_URL.GAME_INFO_GAME_INFO}/${id}/${currentApp}`;
        history.push({ pathname: path });
    }

    return loading ? (<RingLoading />) : (
        <div style = {{paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
            <Grid
                container
                spacing={2}
                className="headerToolbarGrid"
                alignItems={"center"}
            >
                <Grid item xs={12} md={6} lg={3}>
                    <div className="aviron-title">Game Info Management</div>
                </Grid>
                <Grid item xs={12} md={6} lg={9} className="buttonGroup">
                    <Button
                        variant="contained"
                        onClick={onClickAddMore}
                        color="secondary"
                        className="btn-primary"
                        data-cy="add-machine-btn"
                        style={{ minWidth: torem(120), marginLeft: torem(16) }}
                    >
                        <i className="icon-e-add" /> ADD GAME
                    </Button>
                </Grid>
            </Grid>
            <div className={style['Search']}>
              <Grid
                item
                xs={6}
                className={style["search-input"]}
              >
                <Select
                  value={currentApp}
                  onChange={onChangeApp}
                  name="app_selection"
                  fullWidth
                  style={{ minWidth: torem(150) }}
                >
                  {apps?.map(app => {
                      return (
                          <MenuItem value={app.ID} key={`appOption-${app.ID}`}>{app.Name.toUpperCase()}</MenuItem>
                      )
                  })}
                </Select>
                <Select
                  value={currentGt}
                  onChange={filterType}
                  name="game_type_selection"
                  displayEmpty={true}
                  fullWidth
                  style={{ minWidth: torem(150) }}
                >
                  <MenuItem value={-1} key={`appOption-none`}>Select Game Types</MenuItem>
                  {gameTypes?.map(gt => {
                      return (
                          <MenuItem value={gt.ID} key={`appOption-${gt.ID}`}>{gt.ID + '-' + gt.Name}</MenuItem>
                      )
                  })}
                </Select>
                <InputDelay
                  name="input_search"
                  handleSearch={handleSearch}
                  props={{
                    placeholder: "Search by name",
                    type: "text",
                    style: { minWidth: torem(250) }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                alignContent="flex-end"
                className={"numbersPagingWrapper"}
              >
                <PaginationControlled
                  handleChangePage={handleChangePage}
                  count={totalPage}
                  currentPage={currentPage}
                />
              </Grid>
            </div>
            <div className="table">
                <Table className="video-list-table custom-table-element" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    key={`columns-${column.id}`}
                                >
                                            {column.label}
                                </TableCell>
                            ))}
                            <TableCell key="action">
                                <span className="column-no">Actions</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (gameInfo || []).map(el => {
                            return (
                                <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${el.ID}`}>
                                    <TableCell>
                                        {el.ID} 
                                    </TableCell>
                                    <TableCell>
                                        {el.Name} 
                                    </TableCell>
                                    <TableCell>
                                        {el.GameTypeID != null ? el.GameTypeID : '-'} 
                                    </TableCell>
                                    <TableCell>
                                        {el.GameplayConfigFile ? el.GameplayConfigFile : '-'} 
                                    </TableCell>
                                    <TableCell>
                                        {el.GameOptionID ? el.GameOptionID : '-'} 
                                    </TableCell>
                                    <TableCell>
                                        {el.Thumbnail ? el.Thumbnail : '-'} 
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                          variant="outlined"
                                          style={{ marginRight: torem(16) }}
                                          startIcon={<EditIcon />}
                                          onClick={() => onClickEdit(el.ID)}
                                      >
                                          Edit
                                      </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
