import { BaseAPI } from "./base";
import { ENV } from "scripts/apps/environment";
import axios, { AxiosRequestConfig } from "axios"
const FormData = require('form-data');

export class gameThumbUploadAPI {
  async uploadThumb(
    file: File,
  ) {
    console.log('uploadThumb file = ', file)
        const data: any = {
        "module": "game-thumbnail",
        "customId": "",
        "fileName": file.name,
        "clientId": ENV.CLIENT_ID_UPLOAD,
        "secretKey": ENV.SECRET_KEY_UPLOAD
    }
    const requestConfig: AxiosRequestConfig = {
      url: ENV.UPLOAD_SERVICE_URL + '/upload/get-signed-url',
      method: "POST",
      data
    }
    const {data: uploadUrlData } = await axios(requestConfig);


    var form = new FormData();
      form.append('file', file);
    const url: string = uploadUrlData?.signedUrl;
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url,
      headers: { 
        'Content-Type': file.type
      },
      data : form
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    return {thumbUrl: uploadUrlData?.url, name: file.name}
  }
}
