import * as yup from "yup";
import { urlFormatReg } from 'utils/util'

export const slugValidation = (slug: any) => {
    const noSpecialCharacterReg = /^[A-Za-z0-9 \<>\_-]+$/;
    if (slug && slug !== "") {
        if (slug[0]==='/') {
            return {
                isValid: false,
                errorMessage: "Slug must not contain '/' at first letter",
            };
        }
        else if(slug.includes(' ')) {
            return {
                isValid: false,
                errorMessage: "Slug must not contain space letter",
            };
        } 
        else if(!noSpecialCharacterReg.test(slug)) {
            return {
                isValid: false,
                errorMessage: "Slug must not contain specific character",
            };
        }
    }
    return {
        isValid: true,
    };
};

export const linkValidation = (link: any) => {
    // console.log({link})
    if(link && link !=="") {
        if (!urlFormatReg.test(link)) {
            return {
                isValid: false,
                errorMessage: "link must be a valid https url",
            };
        }
    }
    return {
        isValid: true,
    };
};

export const formSchema = yup.object().shape({
    Name: yup.string().max(255).required('Name is a required field').trim(),
    AppID: yup.string().required('App is a required field').trim(),
    GameTypeID: yup.string().required('Type is a required field').trim(),
});