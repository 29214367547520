import { toast } from "react-toastify";

interface renderNotiProps {
  message: string;
  status: "success" | "error" | "loading";
  autoClose?: number;
  closeOnClick?: boolean;
}
export const handleRenderNotification = (props: renderNotiProps) => {
  const {
    autoClose = 2000,
    closeOnClick = true,
    message,
    status,
  } = props || {};
  switch (status) {
    // case "loading":
    //   return toast.loading(message, {
    //     autoClose,
    //     closeOnClick,
    //   });
    case "success":
      return toast.success(message, {
        autoClose,
        closeOnClick,
      });
    case "error":
      return toast.error(message, {
        autoClose,
        closeOnClick,
      });

    default:
      break;
  }
};
