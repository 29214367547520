import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Input,
    Select,
    Button,
    Checkbox,
    FormControlLabel,
    SelectAutoComplete,
    DialogContent,
    DialogContentText,
    TextField,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameTypesHook from "./GameTypes.hook";

const Modes = ["SinglePlayer", "Multiplayer", "Live", "TimeMachine"]

export const GameTypes = () => {
    const {
        loading,
        gameTypes,
        columns,
        handleSelectGameMode,
        addDummyGameType,
        onChangeName,
        onChangeNumberPlayer,
        onChangeHasHighscore,
        onSaveChange,
    } = useGameTypesHook();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const mapGameModeToSelected = (gameModes: string[]) => {
        return gameModes.map(item => {
            return {
                value: item,
                label: item
            }
        })
    }

    return loading ? (<RingLoading />) : (
        <div style = {{paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
            <Grid
                container
                spacing={2}
                className="headerToolbarGrid"
                alignItems={"center"}
            >
                <Grid item xs={12} md={6} lg={3}>
                    <div className="aviron-title">Game Types Management</div>
                </Grid>
                <Grid item xs={12} md={6} lg={9} className="buttonGroup">
                    <Button
                        variant="contained"
                        onClick={addDummyGameType}
                        color="secondary"
                        className="btn-primary"
                        data-cy="add-machine-btn"
                    >
                        <i className="icon-e-add" /> ADD GAME TYPE
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onSaveChange}
                        color="secondary"
                        className="btn-secondary"
                        data-cy="add-machine-btn"
                    >
                        <i className="icon-secondary" /> SAVE
                    </Button>
                </Grid>
            </Grid>
            <div style={{fontSize: '18px', textTransform: 'uppercase', margin: '30px 0'}}>
                <b>game types are using for all apps: apk/c2/go/wr/bike ---- </b>
                <b style={{color: 'red'}}>please careful in case editing</b>
            </div>
            <div className="table">
                <Table className="video-list-table custom-table-element" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    key={`columns-${column.id}`}
                                    width={column.width}
                                >
                                            {column.label}
                                </TableCell>
                            ))}
                            <TableCell key="action">
                                <span className="column-no">Actions</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        gameTypes.map(el => {
                            return (
                                <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${el.ID}`}>
                                    <TableCell>
                                        {el.ID}
                                    </TableCell>
                                    <TableCell>                                        
                                        <TextField 
                                            fullWidth 
                                            name={el.ID.toString()}
                                            defaultValue={el.Name} 
                                            onBlur={onChangeName}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <SelectAutoComplete
                                                    fullWidth
                                                    tag="p"
                                                    name={'selector-gamemode-' + el.ID}
                                                    placeholder="Select Mode"
                                                    multiple
                                                    options={Modes.map((item)=>{
                                                        return {
                                                            value: item,
                                                            label: item
                                                        }
                                                    })}
                                                    onChange={handleSelectGameMode}
                                                    inputProps={{ name: el.ID }}
                                                    value={mapGameModeToSelected(el.GameMode)}
                                                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                                />
                                            </DialogContentText>
                                        </DialogContent>
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            name={el.ID.toString()}
                                            defaultValue={el.NumberPlayer} 
                                            onBlur={onChangeNumberPlayer}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            name={el.ID.toString()}
                                            defaultValue={el.HasHighscore} 
                                            onBlur={onChangeHasHighscore}
                                        />
                                    </TableCell>
                                    <TableCell>
                                    <div className="position-relative">
                                            <button
                                                aria-controls={`action-menu-${el.ID}`}
                                                aria-haspopup="true"
                                                aria-describedby={el.ID.toString()}
                                                className="actions-table pointer"
                                                // variant="contained"
                                                role="presentation"
                                                onClick={(event) => {
                                                    
                                                    setAnchorEl(
                                                        event.currentTarget
                                                    );
                                                    // handleClose(
                                                    //     event,
                                                    //     el.ID
                                                    // );
                                                }}
                                                data-cy="cms-table-action-btn"
                                            >
                                                <i className="icon-ic_more_vert" />
                                            </button>
                                            <Menu
                                                id={`action-menu-${el.ID}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                //open={Boolean(anchorEl)}
                                                open={el.open}
                                                onClose={(event) => {
                                                    setAnchorEl(null);
                                                    // handleClose(event, el.ID);
                                                }}
                                                data-cy="cms-table-action-menu"
                                            >
                                                <MenuItem
                                                    className="actions-table-delete pointer"
                                                    role="presentation"
                                                    // onBlur={(event) =>
                                                    //     handleClose(
                                                    //         event,
                                                    //         el.ID
                                                    //     )
                                                    // }
                                                    // onClick={(event) => handleDelete(el)}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
